export default {
  colors: {
    text: '#111',
    light: '#8a8a8a',
    background: '#fff',
    primary: '#F4BC33',
    secondary: '#ff6347',
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
        primary: '#0cf',
      },
    },
  },
  fonts: {
    body: 'Lato, cursive',
    heading: 'Lato, cursive',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: ['768px', '900px', '1200px'],
  cards: {
    boxShadow: '0 3px 12px rgba(0,0,0,.2)',
  },
  buttons: {
    eventToggles: {
      border: '1px solid #eee',
      background: 'transparent',
      paddingX: 4,
      paddingY: 3,
      color: 'text',
      fontSize: 3,
      fontWeight: '600',
      borderRadius: '30px',
      display: 'inline-block',
      minWidth: '320px',
      cursor: 'pointer',
      outline: 'none',

      '&:hover': {
        background: '#eee',
        transition: 'all .3s',
      },
    },
    splashButton: {
      background: 'rgba(0,0,0,.3)',
      color: '#fff',
      borderRadius: '30px',
      border: '2px solid #fff',
      paddingX: 4,
      fontSize: 2,
      fontWeight: 'heading',
    },
    fill: {
      background: 'primary',
      fontSize: 3,
      paddingY: 3,
    },
    hollow: {
      background: 'transparent',
      color: 'white',
      border: '1px solid white',
      fontSize: 3,
      paddingY: 3,
    }
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontSize: 6, 
      mb: 3,
      fontWeight: '600',
      textTransform: 'uppercase'
    },
    footerDetails: {
      fontFamily: 'body', 
      fontSize: 3,
      color: '#fff',
      textAlign: 'center',
      mb: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center'
    }
  },
  styles: {
    a: {
      color: 'white',
      textDecoration: 'none',
    },
    root: {
      fontFamily: 'body',
    },
  },
};
