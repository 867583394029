// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-theme-minimal-src-pages-agw-partners-js": () => import("./../../../gatsby-theme-minimal/src/pages/AGWPartners.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-agw-partners-js" */),
  "component---gatsby-theme-minimal-src-pages-blog-js": () => import("./../../../gatsby-theme-minimal/src/pages/blog.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-blog-js" */),
  "component---gatsby-theme-minimal-src-pages-events-js": () => import("./../../../gatsby-theme-minimal/src/pages/events.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-events-js" */),
  "component---gatsby-theme-minimal-src-pages-index-js": () => import("./../../../gatsby-theme-minimal/src/pages/index.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-index-js" */),
  "component---gatsby-theme-minimal-src-pages-places-js": () => import("./../../../gatsby-theme-minimal/src/pages/places.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-places-js" */),
  "component---gatsby-theme-minimal-src-pages-vacant-spaces-js": () => import("./../../../gatsby-theme-minimal/src/pages/vacant-spaces.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-pages-vacant-spaces-js" */),
  "component---gatsby-theme-minimal-src-templates-business-page-js": () => import("./../../../gatsby-theme-minimal/src/templates/business-page.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-business-page-js" */),
  "component---gatsby-theme-minimal-src-templates-events-page-template-js": () => import("./../../../gatsby-theme-minimal/src/templates/eventsPageTemplate.js" /* webpackChunkName: "component---gatsby-theme-minimal-src-templates-events-page-template-js" */)
}

